@import-normalize;

a {
    text-decoration: none;
    outline: 0;
}

.g-table-header thead tr th {
    padding-top: 0;
    padding-bottom: 0;
}

.g-table-header thead tr th:last-child {
    text-align: right;
}

.g-table-body-last-col-right tbody td:last-child {
    text-align: left !important;
}

.g-table-no-header thead {
    display: none;
}

.g-table-body-last-col-right tbody tr td, .g-table-body-last-col-right tbody tr th {
    background: transparent !important;
}

.g-table-header tbody tr td, .g-table-header tbody tr th {
    background: transparent !important;
}

body.light > div > div[aria-hidden=false] > div > div {
    background: #fff !important;
    border: 1px solid #f3f3f3;
}

body.dark > div > div[aria-hidden=false] > div > div {
    background: #1A212A !important;
    border: 1px solid #262f3d;
}

button[role=tab][aria-selected=false] div {
    border-color: transparent !important;
    color: #768597 !important
}

button[role=tab][aria-selected=true] div {
    border-color: #1AC486 !important;
    color: #1AC486 !important
}

b {
    font-weight: 600 !important
}

#select-no-shadow {
    box-shadow: none !important
}

div[data-g-portal-id="0"] {
    box-shadow: none !important
}

.value-trans {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 900px) {
    .value-trans {
        flex-direction: column;
    }
}

.menu-trace-log {
    padding: 0px !important;
    border: none !important
}

.menu-trace-log:hover {
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
}

